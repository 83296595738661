import {DotCard} from '@digital-ai/dot-components';
import styled from 'styled-components';

export const SectionHeader = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 0px 0px 8px 8px;

    .section-header {
      flex-grow: 1;
    }
`;

export const CardContainer = styled(DotCard)`
    padding: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
