import PropTypes from 'prop-types';
import React, {
    createContext, useContext, useEffect, useMemo, useState
} from 'react';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import Keycloak from 'keycloak-js';
import {useSearchParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {DotCoreApiProvider, useDotSnackbarContext} from '@digital-ai/dot-components';
import timezones from 'timezones.json';
import {
    getK6iConfigForVanityDomain, validate, logout as authLogout, login,
    getK6iConfigForConfigUrl,
    ssoLogin
} from '../api/authThunk';
import {authTokenUpdate} from '../stores/AuthSlice';
import Loader from '../components/Loader/Loader';
import GlobalConst, {EXCLUDED_PATHS_FOR_REDIRECT, ROUTES} from '../constants/global';
import token from '../api/token';
import {SESSION_EXPIRED_ERROR_MESSAGE} from '../api/utils';
import usePendo from '../hooks/usePendo';

const KEYCLOAK_CLIENT_ID = 'dai-svc-consumer';

export const AuthenticationContext = createContext({
    isAuthLoading: true,
    isSso: false,
    hasVanityDomainInUrl: false,
    isAuthenticated: false,
    isAuthenticating: false,
    isLoggingOut: false,
    logout: () => {},
    // eslint-disable-next-line no-unused-vars
    usernameAndPasswordLogin: ({email, password, remember}) => {},
    vanityDomain: null
});

const CONFIG_URL_SEARCH_PARAM = 'config_url';
const SSO_IS_LOGGING_OUT_LOCAL_STORAGE_KEY = 'SSOIsLoggingOut';

export const AuthenticationProvider = ({children}) => {
    const dispatch = useDispatch();
    const {enqueueMessage} = useDotSnackbarContext();
    const {pathname, search} = useLocation();
    const {initializePendo} = usePendo();
    const [searchParams] = useSearchParams();

    // Common
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isSso, setIsSso] = useState(false);
    const [sessionVanityDomain, setSessionVanityDomain] = useState('');

    // SSO
    const [keycloak, setKeycloak] = useState(null);
    const [identityServiceApiUrl, setIdentityServiceApiUrl] = useState(null);
    const [hasVanityDomainInUrl, setHasVanityDomainInUrl] = useState(false);
    const k6iConfiguration = useSelector((state) => state.authReducer.sso.k6iConfiguration);
    const k6iConfigurationError = useSelector((state) => state.authReducer.sso.k6iConfigurationError);
    const userProvisioned = useSelector((state) => state.authReducer.sso.userProvisioned);
    const userProvisioningError = useSelector((state) => state.authReducer.sso.userProvisioningError);

    // Profile
    const profileIsLoading = useSelector((state) => state.profileReducer.status.loading);
    const profileIsAuthorized = useSelector((state) => state.profileReducer.status.authorized);
    const profileIsValidated = useSelector((state) => state.profileReducer.status.validated);
    const profile = useSelector((state) => state.profileReducer.profile);

    const isAuthExpired = useSelector((state) => state.authReducer.sessionExpired);

    const usernameAndPasswordLogin = ({email, password, remember}) => {
        setIsAuthenticating(true);
        dispatch(login({email, password, remember}));
    };

    const logout = () => {
        if (keycloak?.authenticated) {
            try {
                if (!hasVanityDomainInUrl) {
                    localStorage.setItem(SSO_IS_LOGGING_OUT_LOCAL_STORAGE_KEY, 'true');
                }
                keycloak.logout({
                    redirectUri: window.location.origin
                });
                return;
            // eslint-disable-next-line no-empty
            } catch (err) {}
        }

        dispatch(authLogout());

        // reset status
        setTimeout(() => {
            setKeycloak(null);
            setIsAuthLoading(false);
            setIsAuthenticating(false);
            setIsAuthenticated(false);
            setIsLoggingOut(false);
            setIsSso(false);
        });
    };

    const keycloakLogin = () => {
        if (!keycloak) {
            return;
        }

        const returnTo = EXCLUDED_PATHS_FOR_REDIRECT.includes(pathname) ? null : `${pathname}${search}`;
        const redirectUri = new URL(ROUTES.ssoLogin.route, window.location.origin);
        if (returnTo) {
            redirectUri.searchParams.set('return_to', returnTo);
        }
        keycloak.login({
            redirectUri: redirectUri.toString()
        });
    };

    const authenticate = () => {
        setIsAuthenticating(true);
        dispatch(validate());
    };

    const onKeycloakError = (message) => {
        if (message) {
            enqueueMessage(message, 'error');
        }
        logout();
    };

    const onKeycloakTokenUpdate = () => {
        dispatch(authTokenUpdate(keycloak.token));
        authenticate();
    };

    const onKeycloakAuthSuccess = async () => {
        if (pathname !== ROUTES.ssoLogin.route) {
            onKeycloakTokenUpdate();
            return;
        }

        const offset = dayjs().utcOffset() / 60;
        const possibleTimezones = timezones.filter((timezone) => timezone.offset === offset);
        const timezone = possibleTimezones[0].text;

        dispatch(ssoLogin({
            code: keycloak.token,
            realm: sessionVanityDomain,
            timezone
        }));
    };

    const onkeycloakTokenExpired = async () => {
        try {
            await keycloak.updateToken();
        } catch (error) {
            keycloakLogin();
        }
    };

    const createKeycloak = (identityServerUrl, vanityDomain) => {
        const kc = new Keycloak({
            clientId: KEYCLOAK_CLIENT_ID,
            url: identityServerUrl,
            realm: vanityDomain
        });
        setSessionVanityDomain(vanityDomain);
        setKeycloak(kc);
    };

    useEffect(() => {
        if (isAuthExpired && isAuthenticated) {
            enqueueMessage(SESSION_EXPIRED_ERROR_MESSAGE, 'error');
            logout();
        }
    }, [isAuthExpired]);

    // Detect auth flow
    useEffect(() => {
        if (localStorage.getItem(SSO_IS_LOGGING_OUT_LOCAL_STORAGE_KEY) !== null) {
            localStorage.removeItem(SSO_IS_LOGGING_OUT_LOCAL_STORAGE_KEY);
            logout();
            return;
        }

        const vanityDomain = GlobalConst.ADP_VANITY_DOMAIN;
        const hasVanityDomain = !isEmpty(vanityDomain);
        setHasVanityDomainInUrl(hasVanityDomain);
        let configUrl;
        if (pathname === ROUTES.ssoLogin.route && searchParams.has(CONFIG_URL_SEARCH_PARAM)) {
            configUrl = searchParams.get(CONFIG_URL_SEARCH_PARAM);
        }

        const hasSsoConfigUrl = !isEmpty(configUrl);
        setIsSso(hasVanityDomain || hasSsoConfigUrl);

        // SSO flow with vanity domain
        if (hasVanityDomain) {
            dispatch(getK6iConfigForVanityDomain(vanityDomain));
            return;
        }

        // SSO flow with config url
        if (hasSsoConfigUrl) {
            dispatch(getK6iConfigForConfigUrl(configUrl));
            return;
        }

        // Basic login flow
        if (isEmpty(token.get())) {
            setIsAuthLoading(false);
            return;
        }
        authenticate();
    }, []);

    // On k6iConfiguration populated
    useEffect(() => {
        if (!k6iConfiguration) {
            return;
        }

        let identityServerUrl = null;
        let vanityDomain = null;
        const {issuer} = k6iConfiguration;
        const issuerChunks = issuer.split('/realms/');
        if (issuerChunks.length === 2) {
            [identityServerUrl, vanityDomain] = issuerChunks;
        }

        if (!hasVanityDomainInUrl) {
            const vanityDomainUrl = new URL(window.location.href);
            vanityDomainUrl.host = `${vanityDomain}.${vanityDomainUrl.host}`;
            window.location.href = vanityDomainUrl.href;
            return;
        }

        const issuerUrl = new URL(issuer);
        const {host, protocol} = issuerUrl;
        const apiUrl = `${protocol}//${host.replace('identity', 'api')}`;
        setIdentityServiceApiUrl(apiUrl);

        createKeycloak(identityServerUrl, vanityDomain);
    }, [k6iConfiguration]);

    // On k6iConfiguration error
    useEffect(() => {
        if (k6iConfigurationError) {
            window.location.href = GlobalConst.ADP_FRONTEND_URL;
        }
    }, [k6iConfigurationError]);

    // On keycloak created
    useEffect(async () => {
        if (!keycloak) {
            return;
        }

        keycloak.onAuthSuccess = () => onKeycloakAuthSuccess();
        keycloak.onAuthLogout = () => logout();
        keycloak.onAuthError = ({error}) => onKeycloakError(error);
        keycloak.onAuthRefreshSuccess = () => onKeycloakTokenUpdate();
        keycloak.onAuthRefreshError = () => onKeycloakError();
        keycloak.onTokenExpired = () => onkeycloakTokenExpired();

        const initParams = {
            onLoad: 'check-sso'
        };

        // Initialize keycloak so it checks if the user is already authenticated
        // If not it will redirect to the SSO Login
        const authenticated = await keycloak.init(initParams);
        if (!authenticated) {
            keycloakLogin();
        }
    }, [keycloak]);

    // When sso user has completed the login in the core-api
    useEffect(() => {
        if (!userProvisioned) {
            return;
        }

        onKeycloakTokenUpdate();
    }, [userProvisioned]);

    // In case the sso login against core-api fails
    useEffect(() => {
        if (!userProvisioningError) {
            return;
        }

        setIsAuthenticated(false);
        setIsAuthenticating(false);
        setIsAuthLoading(false);
    }, [userProvisioningError]);

    // When profile is validated
    useEffect(() => {
        if (!isAuthenticating || profileIsLoading) {
            return;
        }

        setIsAuthenticated(profileIsAuthorized && profileIsValidated);
        setIsAuthenticating(false);
        setIsAuthLoading(false);
    }, [
        profileIsLoading,
        profileIsAuthorized,
        profileIsValidated
    ]);

    // Perform actions when user is authenticated
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        initializePendo(profile);
    }, [isAuthenticated]);

    const memoizedValues = useMemo(() => ({
        isAuthLoading,
        isAuthenticated,
        isAuthenticating,
        isLoggingOut,
        isSso,
        hasVanityDomainInUrl,
        vanityDomain: sessionVanityDomain,
        usernameAndPasswordLogin,
        logout: () => {
            setIsLoggingOut(true);
            logout();
        }
    }), [
        isAuthLoading,
        isAuthenticated,
        isAuthenticating,
        isLoggingOut,
        isSso,
        hasVanityDomainInUrl
    ]);

    return <Loader loading={isAuthLoading}>
        {!isAuthLoading && <DotCoreApiProvider
            apiUrl={isSso && isAuthenticated ? identityServiceApiUrl : null}
            token={isSso && isAuthenticated ? keycloak?.token : null}>
            <AuthenticationContext.Provider value={memoizedValues}>
                {children}
            </AuthenticationContext.Provider>
        </DotCoreApiProvider>}
    </Loader>;
};

AuthenticationProvider.propTypes = {
    children: PropTypes.any
};

export const useAuthentication = () => useContext(AuthenticationContext);
