import {
    camelCase,
    deburr,
    escape,
    upperFirst
} from 'lodash';
import {useState} from 'react';
import {md5} from 'js-md5';
import {PENDO_ENABLED, PENDO_PREFIX} from '../constants/environment';

const {pendo} = window;

const usePendo = () => {
    const [pendoIsInitialized, setPendoIsInitialized] = useState(false);

    const initializePendo = (user) => {
        const {email, role, company} = user;
        const {id, name, pendoEnabled} = company;

        if (!PENDO_ENABLED || !pendoEnabled) {
            return;
        }

        if (!user || pendoIsInitialized) {
            return;
        }
        const userIdHash = md5(email);
        const accountName = upperFirst(camelCase(deburr(escape(name))));

        const identifier = `${accountName}_${id}_${PENDO_PREFIX}`;
        pendo.initialize({
            account: {
                id: identifier,
                name
            },
            visitor: {
                id: `${identifier}/${userIdHash}`,
                role
            }
        });
        setPendoIsInitialized(true);
    };

    return {initializePendo};
};

export default usePendo;
