import React from 'react';
import PropTypes from 'prop-types';
import {
    DotAvatar, DotCheckbox, DotDivider, DotTypography
} from '@digital-ai/dot-components';
import {CardContainer, SectionHeader} from './AccountAnalyticsSetting.styles';

const AccountAnalyticsSetting = ({accountAnalyticsEnabled, setAccountAnalyticsEnabled, disabled = false}) => (
    <CardContainer data-testid='account-analytics-setting'>
        <SectionHeader>
            <DotAvatar iconId='announcement' alt='Analytics' />
            <section>
                <DotTypography
                    className='section-header'
                    component='h3'
                    variant='h3'
                >
                    Product analytics and guidance
                </DotTypography>
                <DotTypography variant='body1'>
                    Allow the application to provide personalized feature
                    guidance by tracking anonymous usage data
                </DotTypography>
            </section>
        </SectionHeader>
        <DotDivider/>
        <section>
            <DotCheckbox
                className='analytics-enabled-checkbox'
                data-testid='account-analytics-setting-checkbox'
                checked={accountAnalyticsEnabled}
                disabled={disabled}
                label='Enable the analytics and guidance feature'
                onChange={(event) => setAccountAnalyticsEnabled(event.target.checked)}
            />
        </section>
    </CardContainer>
);

AccountAnalyticsSetting.propTypes = {
    accountAnalyticsEnabled: PropTypes.bool,
    disabled: PropTypes.bool,
    setAccountAnalyticsEnabled: PropTypes.func
};

export default AccountAnalyticsSetting;
