/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ActionsContainer = styled.section`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
`;
