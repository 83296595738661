import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isNil} from 'lodash';
import {
    DotButton,
    useDotSnackbarContext
} from '@digital-ai/dot-components';
import Page from '../../components/Page/Page';
import {ActionsContainer} from './AccountPage.styles';
import {updateOwnCompany} from '../../api/companiesThunk';
import AccountAnalyticsSetting from '../../components/AccountAnalyticsSetting/AccountAnalyticsSetting';

const AccountPage = () => {
    const dispatch = useDispatch();
    const {enqueueMessage} = useDotSnackbarContext();

    const companiesError = useSelector((state) => state.companiesReducer.status.error);
    const companiesErrorMessage = useSelector((state) => state.companiesReducer.errorMessage);
    const companyUpdated = useSelector((state) => state.companiesReducer.status.updated);

    const profileCompany = useSelector((state) => state.profileReducer.profile.company);

    const [company, setCompany] = useState(null);
    const [accountAnalyticsEnabled, setAccountAnalyticsEnabled] = useState(false);

    // Set profile company on initial load
    useEffect(() => {
        setCompany(profileCompany);
    }, []);

    // Update current values when company changes
    useEffect(() => {
        let pendoEnabled = false;

        if (company) {
            pendoEnabled = company.pendoEnabled;
        }

        setAccountAnalyticsEnabled(pendoEnabled);
    }, [company]);

    // Show message when settings are saved,
    // update company so changes are shown
    useEffect(() => {
        if (!companyUpdated) {
            return;
        }
        window.location.reload();
    }, [companyUpdated]);

    useEffect(() => {
        if (!companiesError) {
            return;
        }

        enqueueMessage(companiesErrorMessage, 'error');
    }, [companiesError]);

    const canSave = () => !isNil(company) && [
        accountAnalyticsEnabled !== company.pendoEnabled
    ].some(Boolean);

    const reset = () => {
        setAccountAnalyticsEnabled(company.pendoEnabled);
    };

    const submit = () => {
        dispatch(updateOwnCompany({pendoEnabled: accountAnalyticsEnabled}));
    };

    return <Page width='50%'>
        <AccountAnalyticsSetting
            accountAnalyticsEnabled={accountAnalyticsEnabled}
            setAccountAnalyticsEnabled={(value) => setAccountAnalyticsEnabled(value)}
        />
        <ActionsContainer data-testid='account-analytics-setting-actions'>
            <DotButton
                type='text'
                disabled={!canSave()}
                onClick={reset}
                data-testid='account-analytics-setting-action-reset'
            >Reset</DotButton>
            <DotButton
                type='primary'
                disabled={!canSave()}
                onClick={submit}
                data-testid='account-analytics-setting-action-save'
            >Save</DotButton>
        </ActionsContainer>
    </Page>;
};

export default AccountPage;
