import {createAsyncThunk} from '@reduxjs/toolkit';
import fetchRequest from './utils';

export const getCompanies = createAsyncThunk('companies/getCompanies', async (params) => fetchRequest('/companies', 'GET', params));

export const getCompaniesWithContracts = createAsyncThunk('companies/getCompaniesWithContracts', async (params) => fetchRequest('/companies/contracts', 'GET', params));

export const getEntitledCompanies = createAsyncThunk('companies/getEntitledCompanies', async (productKeys) => fetchRequest(`/companies/entitled`, 'POST', null, {productKeys}));

export const updateCompany = createAsyncThunk('companies/updateCompany',
    async (body) => {
        // Destructuring the whole data so we don't have to do it on the component
        const {
            id,
            masterAccess,
            nonNodeLockedLicense,
            ssoLoginEnabled,
            ssoRegion,
            ssoRealm,
            pendoEnabled
        } = body;
        return fetchRequest(`/companies/${id}`, 'PUT', null, {
            masterAccess,
            nonNodeLockedLicense,
            ssoLoginEnabled,
            ssoRegion,
            ssoRealm,
            pendoEnabled
        });
    }
);

export const updateOwnCompany = createAsyncThunk('companies/updateOwnCompany',
    async (body) => {
        // Destructuring the whole data so we don't have to do it on the component
        const {
            pendoEnabled
        } = body;
        return fetchRequest(`/companies`, 'PUT', null, {
            pendoEnabled
        });
    }
);

export const deleteCompany = createAsyncThunk('companies/deleteCompany', async (id) => fetchRequest(`/companies/${id}`, 'DELETE', null));
