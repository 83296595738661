import {browserLocation} from '../utils/browser';

const {hostname} = browserLocation;

// These regex only take into account the digital.ai domain where vanity domains can be used, as for the arxan domain there
// are no vanity domains and we don't need regex for them. At some point we'll just use the digital.ai domain and this will
// be clearer.
// In all these regex, group 1 is the vanity domain, and group 2 the base domain. `?:` is used to avoid capturing the group.
const localWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(adp\.local\.digitalai\.cloud)$/;
const localqanaWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(qana-adp\.local\.digitalai\.cloud)$/;
const qanaWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(appsec\.dev\.digital\.ai)$/;
const stagingWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(appsec\.staging\.digital\.ai)$/;
const prodNAWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(appsec\.us\.digital\.ai)$/;
const prodEUWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(appsec\.eu\.digital\.ai)$/;
const pullRequestForQanaWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(qana-appsec-pr\d+\.appsec\.dev\.digital\.ai)$/;
const pullRequestWithPossibleVanityDomainsRegex = /^(?:(^.+)\.)*(appsec-pr\d+\.appsec\.dev\.digital\.ai)$/;

const getEnvironmentName = () => {
    const isDomainOrSubdomain = (domain) => hostname === domain || hostname.endsWith(`.${domain}`);

    if (isDomainOrSubdomain('appsec.us.digital.ai') || isDomainOrSubdomain('developer.arxan.com')) {
        return 'prodna';
    }
    if (isDomainOrSubdomain('appsec.eu.digital.ai') || isDomainOrSubdomain('developer.arxan.eu')) {
        return 'prodeu';
    }
    if (isDomainOrSubdomain('appsec.staging.digital.ai') || isDomainOrSubdomain('devstaging.arxan.com')) {
        return 'staging';
    }
    // Note that this one needs to be before qana's one, as otherwise it would match the pull request regex.
    if (hostname.match(pullRequestForQanaWithPossibleVanityDomainsRegex)) {
        return 'pull_request_qana';
    }
    if (hostname.match(pullRequestWithPossibleVanityDomainsRegex)) {
        return 'pull_request';
    }
    if (isDomainOrSubdomain('appsec.dev.digital.ai') || isDomainOrSubdomain('qana.arxan.com')) {
        return 'qana';
    }
    if (isDomainOrSubdomain('qana-adp.local.digitalai.cloud')) {
        return 'localqana';
    }
    if (isDomainOrSubdomain('adp.local.digitalai.cloud')) {
        return 'local';
    }
    throw new Error('Incorrect hostname');
};

const getEnvironmentValue = (key) => {
    const environmentValues = {
        ADP_MOCK_DATA_ENABLED: {
            local: true,
            localqana: true,
            qana: false,
            pull_request_qana: false,
            pull_request: false,
            staging: false,
            prodna: false,
            prodeu: false
        },
        ADP_API_URL: {
            local: 'http://api.adp.local.digitalai.cloud',
            localqana: 'https://api.qana.arxan.com',
            qana: 'https://api.qana.arxan.com',
            pull_request_qana: 'https://api.qana.arxan.com',
            pull_request: `https://api-${hostname.match(pullRequestWithPossibleVanityDomainsRegex)?.[2]}`,
            staging: 'https://api.devstaging.arxan.com',
            prodna: 'https://api.developer.arxan.com',
            prodeu: 'https://api.developer.arxan.eu'
        },
        ADP_FRONTEND_URL: {
            local: 'http://adp.local.digitalai.cloud',
            localqana: 'http://qana-adp.local.digitalai.cloud',
            qana: 'https://appsec.dev.digital.ai',
            pull_request_qana: `https://${hostname.match(pullRequestForQanaWithPossibleVanityDomainsRegex)?.[2]}`,
            pull_request: `https://${hostname.match(pullRequestWithPossibleVanityDomainsRegex)?.[2]}`,
            staging: 'https://appsec.staging.digital.ai',
            prodna: 'https://appsec.us.digital.ai',
            prodeu: 'https://appsec.eu.digital.ai'
        },
        ADP_VANITY_DOMAIN: {
            local: hostname.match(localWithPossibleVanityDomainsRegex)?.[1] ?? null,
            localqana: hostname.match(localqanaWithPossibleVanityDomainsRegex)?.[1] ?? null,
            qana: hostname.match(qanaWithPossibleVanityDomainsRegex)?.[1] ?? null,
            pull_request_qana: hostname.match(pullRequestForQanaWithPossibleVanityDomainsRegex)?.[1] ?? null,
            pull_request: hostname.match(pullRequestWithPossibleVanityDomainsRegex)?.[1] ?? null,
            staging: hostname.match(stagingWithPossibleVanityDomainsRegex)?.[1] ?? null,
            prodna: hostname.match(prodNAWithPossibleVanityDomainsRegex)?.[1] ?? null,
            prodeu: hostname.match(prodEUWithPossibleVanityDomainsRegex)?.[1] ?? null
        },
        PENDO_ENABLED: {
            local: false,
            localqana: false,
            qana: false,
            pull_request_qana: false,
            pull_request: false,
            staging: false,
            prodna: true,
            prodeu: true
        },
        PENDO_PREFIX: {
            prodna: 'prodna',
            prodeu: 'prodeu'
        },
        HIDDEN_ROUTES: {
            local: [],
            localqana: [],
            qana: [],
            pull_request_qana: [],
            pull_request: [],
            staging: [],
            prodna: [],
            prodeu: []
        },
        K6I_SIGNIN_PAGE_URL: {
            local: 'https://digitalai.staging.digital.ai/signin',
            localqana: 'https://digitalai.staging.digital.ai/signin',
            qana: 'https://digitalai.staging.digital.ai/signin',
            pull_request_qana: 'https://digitalai.staging.digital.ai/signin',
            pull_request: 'https://digitalai.staging.digital.ai/signin',
            staging: 'https://digitalai.staging.digital.ai/signin',
            prodna: 'https://digitalai.us.digital.ai/signin',
            prodeu: 'https://digitalai.eu.digital.ai/signin'
        },
        APAAS_DISABLED: {
            local: false,
            localqana: false,
            qana: false,
            pull_request_qana: false,
            pull_request: false,
            staging: false,
            prodna: false,
            prodeu: true
        },
        AVAILABLE_K6I_INSTANCES: {
            local: ['STAGING'],
            localqana: ['STAGING', 'US'],
            qana: ['STAGING', 'US'],
            pull_request_qana: ['STAGING', 'US'],
            pull_request: ['STAGING', 'US'],
            staging: ['STAGING', 'US'],
            prodna: ['US', 'EU'],
            prodeu: ['EU', 'US']
        }
    };
    const environmentName = getEnvironmentName();
    return environmentValues[key][environmentName];
};

export const ADP_MOCK_DATA_ENABLED = getEnvironmentValue('ADP_MOCK_DATA_ENABLED');
export const ADP_API_URL = getEnvironmentValue('ADP_API_URL');
export const ADP_FRONTEND_URL = getEnvironmentValue('ADP_FRONTEND_URL');
export const ADP_VANITY_DOMAIN = getEnvironmentValue('ADP_VANITY_DOMAIN');
export const PENDO_ENABLED = getEnvironmentValue('PENDO_ENABLED');
export const PENDO_PREFIX = getEnvironmentValue('PENDO_PREFIX');
export const HIDDEN_ROUTES = getEnvironmentValue('HIDDEN_ROUTES');
export const K6I_SIGNIN_PAGE_URL = getEnvironmentValue('K6I_SIGNIN_PAGE_URL');
export const APAAS_DISABLED = getEnvironmentValue('APAAS_DISABLED');
export const AVAILABLE_K6I_INSTANCES = getEnvironmentValue('AVAILABLE_K6I_INSTANCES');
