/* eslint-disable no-param-reassign */
import {merge} from 'lodash';
import {createSelector, createSlice} from '@reduxjs/toolkit';

import {
    getCompanies, getCompaniesWithContracts, getEntitledCompanies, updateCompany, deleteCompany,
    updateOwnCompany
} from '../api/companiesThunk';

const setPendingState = (state) => {
    const status = {
        loading: true,
        updated: false,
        deleted: false
    };
    state.status = merge(state.status, status);
};

const setRejectedState = (state, action) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false
    };
    state.status = merge(state.status, status);
    state.error = true;
    state.errorMessage = action.error.message;
};

const setGetFulfilledState = (state, action) => {
    const status = {
        loading: false,
        updated: false,
        deleted: false
    };
    state.status = merge(state.status, status);
    state.error = false;
    state.errorMessage = null;
    state.companies = action.payload;
};

const onCompanyUpdated = (state, action) => {
    const updatedCompany = action.payload;
    state.companies = state.companies.map((company) => (company.id === updatedCompany.id ? updatedCompany : company));
    state.status.loading = false;
    state.status.updated = true;
    state.status.deleted = false;
    state.error = false;
    state.errorMessage = null;
};

const initialState = {
    error: null,
    errorMessage: null,
    status: {
        loading: false,
        updated: false,
        deleted: false
    },
    companies: []
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanies.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(getCompaniesWithContracts.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(getEntitledCompanies.fulfilled, (state, action) => {
                setGetFulfilledState(state, action);
            })
            .addCase(updateCompany.fulfilled, onCompanyUpdated)
            .addCase(updateOwnCompany.fulfilled, onCompanyUpdated)
            .addCase(deleteCompany.fulfilled, (state, action) => {
                const deletedCompany = action.payload;
                state.companies = state.companies.filter((company) => company.id !== deletedCompany.id);
                state.status.loading = false;
                state.status.updated = false;
                state.status.deleted = true;
                state.error = false;
                state.errorMessage = null;
            })
            .addMatcher((action) => action.type.endsWith('/pending') && action.type.includes('companies'), (state) => {
                setPendingState(state);
            })
            .addMatcher((action) => action.type.endsWith('/rejected') && action.type.includes('companies'), (state, action) => {
                setRejectedState(state, action);
            });
    }
});

export const selectCompaniesCount = createSelector(
    (state) => state.companiesReducer,
    (companiesReducer) => companiesReducer.companies?.length ?? 0
);

export const selectCompany = createSelector(
    (state, id) => ({companies: state.companies, id}),
    ({companies, id}) => companies.find((company) => company.id === id) ?? null
);

export default companiesSlice.reducer;
