/* eslint-disable no-param-reassign */
import {createSlice, createSelector} from '@reduxjs/toolkit';
import lodash from 'lodash';
import timezones from 'timezones.json';

import GlobalConsts, {ROUTES, ROLES} from '../constants/global';
import Storage from '../utils/storage';
import {saveProfile, saveDeliverySettings} from '../api/profileThunk';
import {
    login, validate, oneTimePassword, setup, reset
} from '../api/authThunk';
import {invalidateAuthentication, authTokenUpdate} from './AuthSlice';
import routeValidator from '../utils/routeValidator';
import {updateOwnCompany} from '../api/companiesThunk';

const isAdmin = (role) => ROLES.admin === role;
const isAccountOwner = (role) => ROLES.accountOwner === role;

// Need to figure out how the routes stuff was previously working
export const fillRoutes = (profile) => Object.values(ROUTES)
    .filter((route) => route.access?.includes(profile.role) && !lodash.includes(GlobalConsts.HIDDEN_ROUTES, route.route))
    .map((route) => route.route)
    .filter((route) => {
        if (isAdmin(profile.role)) {
            return true;
        }
        switch (route) {
            case ROUTES.keyAndData.route:
                return profile.isTransformitActive;
            case ROUTES.manageMaliciousPackageExclusions.route:
                return profile.company.threatAnalytics
                    && profile.threat_analytics_data_access
                    && profile.company.apaas.androidOnPremAvailable
                    && profile.mpdExclusionsDataAccess;
            case ROUTES.productDownload.route:
                return profile.productDownloadEnabled;
            case ROUTES.manageLicenses.route:
            case ROUTES.manageLicensesById.route:
                return profile.company.offlineLicense;
            case ROUTES.appAwareDashboard.route:
            case ROUTES.appAwareAlerts.route:
            case ROUTES.appAwareEvents.route:
            case ROUTES.manageAppAwareSetup.route:
                return profile.company.threatAnalytics
                    && profile.threat_analytics_data_access;
            default:
                return true;
        }
    });

const initialState = {
    error: null,
    routes: [],
    showBanner: true,
    status: {
        loading: false,
        authorized: false,
        validated: false,
        otp: false,
        setup: false,
        resetResult: null,
        saved: false
    },
    profile: {}
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        closeBanner: (state) => {
            state.showBanner = false;
            Storage.setItem(GlobalConsts.NOTIFICATIONS.BANNER_CLOSED, 1);
        },
        saveAcceptedEula: (state) => {
            const eula = {
                company: {
                    acceptedEula: true
                }
            };
            state.profile = {...state.profile, ...eula};
        },
        clearResetResult: (state) => {
            state.status.resetResult = null;
        },
        resetProfileSlice: () => initialState,
        clearError: (state) => {
            state.error = null;
        },
        clearSaved: (state) => {
            state.status.saved = false;
        }
    },
    extraReducers: (builder) => {
        // Login
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.authorized = true;
                state.status.validated = true;
                state.showBanner = true;
                state.error = null;
                state.profile = action.payload;
                state.routes = fillRoutes(state.profile);
            });

        // Validate
        builder
            .addCase(validate.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.authorized = true;
                state.status.validated = true;
                state.error = null;
                state.profile = action.payload;
                state.routes = fillRoutes(state.profile);
            });

        // One time password
        builder
            .addCase(oneTimePassword.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.authorized = true;
                state.status.validated = true;
                state.status.otp = true;
                state.error = null;
                state.profile = action.payload;
                state.routes = fillRoutes(state.profile);
            });

        // Reset
        builder
            .addCase(reset.pending, (state) => {
                state.status.resetResult = null;
                state.routes = fillRoutes(state.profile);
            })
            .addCase(reset.fulfilled, (state) => {
                state.status.resetResult = 'OK';
                state.error = null;
                state.routes = fillRoutes(state.profile);
            })
            .addCase(reset.rejected, (state, action) => {
                let resetResult = 'Error';
                if (action.error.message?.error) {
                    resetResult = action.error.message.error;
                }
                state.status.resetResult = {resetResult};
                state.routes = fillRoutes(state.profile);
            });

        // Save profile
        builder
            .addCase(saveProfile.fulfilled, (state, action) => {
                const nextState = {...action.payload};
                // Reset the profile for changing the password twice or more in a row
                delete nextState.currentPassword;
                delete nextState.createPassword;
                delete nextState.confirmPassword;

                state.status.loading = false;
                state.status.saved = true;
                state.error = null;
                state.profile = {...state.profile, ...nextState};
                state.routes = fillRoutes(state.profile);
            });

        // Save delivery settings
        builder
            .addCase(saveDeliverySettings.fulfilled, (state, action) => {
                state.status.loading = false;
                state.status.saved = true;
                state.error = null;
                state.profile = {...state.profile, deliverySettings: action.payload.deliverySettings};
                state.routes = fillRoutes(state.profile);
            });

        // Setup
        builder
            .addCase(setup.fulfilled, (state) => {
                state.status.loading = false;
                state.status.authorized = false;
                state.status.validated = true;
                state.status.otp = false;
                state.status.setup = true;
                state.error = null;
                state.routes = fillRoutes(state.profile);
            });

        builder
            .addCase(updateOwnCompany.fulfilled, (state, action) => {
                state.profile = {
                    ...state.profile,
                    company: action.payload
                };
            });

        // Matcher for when login or oneTimePassword is loading
        builder
            .addMatcher((action) => [login.pending.type, oneTimePassword.pending.type].includes(action.type), (state) => {
                state.status.loading = true;
                state.error = null;
            });

        // Matcher for when login or oneTimePassword is rejected
        builder
            .addMatcher((action) => [login.rejected.type, oneTimePassword.rejected.type].includes(action.type), (state, action) => {
                state.status.loading = false;
                state.status.authorized = false;
                state.status.validated = true;
                state.error = action.error.message;
                state.routes = fillRoutes(state.profile);
            });

        // Matcher for when invalidateAuthentication from AuthSlice is triggered or validate is rejected
        // To cover 'ActionTypes.AUTH_SESSION_EXPIRED' action: see src/api/utils.js ln:20
        builder
            .addMatcher((action) => [invalidateAuthentication.type, validate.rejected.type].includes(action.type), (state) => {
                state.status.loading = false;
                state.status.authorized = false;
                state.status.validated = true;
                state.routes = fillRoutes(state.profile);
            });

        // Matcher for when authTokenUpdate from AuthSlice is triggered
        builder
            .addMatcher((action) => action.type === authTokenUpdate.type, (state) => {
                state.status.loading = false;
                state.status.authorized = false;
                state.status.validated = false;
                state.routes = fillRoutes(state.profile);
            });

        // Global matcher for pending state
        builder
            .addMatcher((action) => action.type.startsWith('profile') && action.type.endsWith('/pending'), (state) => {
                state.status.loading = true;
            });

        // Global matcher for rejected state
        builder
            .addMatcher((action) => (action.type.startsWith('profile') && action.type.endsWith('/rejected')) || action.type === setup.rejected.type, (state, action) => {
                state.status.loading = false;
                state.error = action.error.message;
            });
    }
});

export const canFilterCompanies = createSelector((profile) => profile.role, isAdmin);
export const selectIsAdmin = createSelector((profile) => profile.role, isAdmin);
export const selectIsSSOUser = createSelector((state) => state.profileReducer.profile, (profile) => profile?.company?.ssoLoginEnabled ?? false);
export const selectIsAccountOwner = createSelector((profile) => profile.role, isAccountOwner);
export const selectTimezoneOffset = createSelector((profile) => profile.timezone, (storedTimezone) => {
    if (!storedTimezone) {
        return null;
    }
    return timezones.find((timezone) => timezone.text === storedTimezone).offset;
});
export const selectTimezoneValue = createSelector(
    (profile) => profile.timezone,
    (storedTimezone) => timezones.find((timezone) => timezone.text === storedTimezone).value
);

export const selectRootRoute = createSelector((state) => state.profile, (profile) => {
    switch (profile.role) {
        case ROLES.admin:
            return ROUTES.releases.route;
        case ROLES.securityResearcher:
            return ROUTES.manageMaliciousPackages.route;
        default:
            return ROUTES.productDownload.route;
    }
});
export const selectCanAccess = createSelector(
    (state, route) => ({routes: state.routes, route}),
    (params) => routeValidator(params.route, params.routes)
);
export const selectCanToggleAppAwareFeature = createSelector(
    (state) => state.profileReducer.profile.role,
    (role) => isAdmin(role) || isAccountOwner(role)
);

export const {
    closeBanner, saveAcceptedEula, clearResetResult, resetProfileSlice, clearError, clearSaved
} = profileSlice.actions;

export const selectFullName = createSelector(
    (store) => store.profileReducer.profile,
    (profile) => (profile.firstname && profile.lastname ? `${profile.firstname} ${profile.lastname}` : '')
);

export default profileSlice.reducer;
